import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

// Estilização principal da seção do formulário
export const ContactCss = styled.section`
  padding: 80px 0;
  background-color: ${Cores.secondary};
`;

// Título da seção e descrição
export const Title = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h2 {
    font-size: 2rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

// Container do formulário
export const FormContainer = styled.form`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr; // Duas colunas
  gap: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Ajuste para coluna única em telas menores
  }
`;

// Seção do formulário com grupos de entrada
export const FormSection = styled.div`
  .input-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  select,
  input {
    padding: 0.8rem 1rem;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    background-color: ${Cores.primary};
    transition: all 0.3s ease;
    font-size: 0.95rem;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      outline: none;
    }

    &:disabled {
      background-color: #e9ecef;
      cursor: not-allowed;
    }
  }

  select {
    cursor: pointer;
    appearance: none; // Remove estilo padrão de dropdown
    background-image: url("data:image/svg+xml,..."); // Adiciona seta customizada
    background-position: right 1rem center;
    background-repeat: no-repeat;
  }
`;

// Descrição do problema (textarea)
export const ProblemDescription = styled.div`
  textarea {
    width: 100%;
    height: 93%;
    min-height: 200px;
    padding: 12px;
    background-color: ${Cores.primary};
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    resize: none; // Remove redimensionamento lateral
    outline: none;
    transition: border-color 0.3s;

    &:focus {
      border-color: #007bff;
    }
  }
`;

// Botão de envio
export const SubmitButton = styled.button`
  grid-column: 1 / -1; // Ocupa todas as colunas
  padding: 16px 32px;
  background-color: #25d366; // Verde padrão do WhatsApp
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  p {
    font-size: 0.8rem;
    color: #fff;
  }

  &:hover {
    filter: brightness(1.5); // Efeito de brilho no hover
  }
`;
