import { useState, FormEvent } from "react";
import {
  ContactCss,
  FormContainer,
  FormSection,
  ProblemDescription,
  SubmitButton,
  Title,
} from "./styles";
import { FaWhatsapp } from "react-icons/fa";
// import { collection, addDoc } from "firebase/firestore";
// import { db } from "../../config/firebase";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    model: "",
    brand: "",
    issue: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validar se todos os campos obrigatórios estão preenchidos
      if (
        !formData.name ||
        !formData.phone ||
        !formData.email ||
        !formData.brand ||
        !formData.model ||
        !formData.issue
      ) {
        throw new Error("Por favor, preencha todos os campos obrigatórios");
      }

      // Preparar mensagem do WhatsApp
      const message = `
*Novo pedido de Orçamento*
Nome: ${formData.name}
Telefone: ${formData.phone}
Email: ${formData.email}
Marca: ${formData.brand}
Modelo: ${formData.model}
Problema: ${formData.issue}
Descrição: ${formData.description}
      `.trim();

      // Abrir WhatsApp
      const whatsappNumber = "16981451428";
      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(whatsappUrl, "_blank");

      // Resetar formulário
      setFormData({
        name: "",
        phone: "",
        email: "",
        model: "",
        brand: "",
        issue: "",
        description: "",
      });

      /* Código do Firebase comentado para uso futuro
      const orcamentoData = {
        ...formData,
        timestamp: new Date(),
        status: "Pendente",
        createdAt: new Date().toISOString(),
      };
      const docRef = await addDoc(collection(db, "Orcamentos"), orcamentoData);
      console.log("Orçamento salvo com ID:", docRef.id);
      */
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const phoneModels = {
    Apple: [
      "iPhone 15 Pro Max",
      "iPhone 15",
      "iPhone 14 Pro",
      "iPhone 13",
      "iPhone SE",
      "iPhone 12",
      "iPhone 11",
      "Outro modelo",
    ],
    Samsung: [
      "Galaxy S24 Ultra",
      "Galaxy S24",
      "Galaxy Z Fold 5",
      "Galaxy Z Flip 5",
      "Galaxy A54",
      "Galaxy S23",
      "Galaxy S22",
      "Galaxy A14",
      "Outro modelo",
    ],
    Motorola: [
      "Edge 40 Pro",
      "Moto G84",
      "Moto G73",
      "Moto G Power",
      "Moto Razr 40 Ultra",
      "Moto G52",
      "Moto E22",
      "Outro modelo",
    ],
    Xiaomi: [
      "Redmi Note 13",
      "13T",
      "Xiaomi 13 Pro",
      "Redmi Note 12 Pro",
      "Poco X5 Pro",
      "Mi 11 Ultra",
      "Redmi 12",
      "Outro modelo",
    ],
    Huawei: [
      "P60 Pro",
      "Mate 50 Pro",
      "Nova 11",
      "P50 Pocket",
      "Y9a",
      "Mate X3",
      "Outro modelo",
    ],
    Google: [
      "Pixel 8 Pro",
      "Pixel 8",
      "Pixel 7",
      "Pixel 7a",
      "Pixel 6a",
      "Pixel Fold",
      "Outro modelo",
    ],
    Asus: [
      "ROG Phone 7 Ultimate",
      "Zenfone 10",
      "ROG Phone 6 Pro",
      "ROG Phone 5",
      "Zenfone 9",
      "Outro modelo",
    ],
    Oppo: [
      "Find X6 Pro",
      "Reno 10 Pro+",
      "Reno 8",
      "A98",
      "Find N2 Flip",
      "Outro modelo",
    ],
    Vivo: ["X90 Pro", "Y100", "V27e", "T2 Pro", "X Fold 2", "Outro modelo"],
    Realme: [
      "Realme 11 Pro+",
      "Realme Narzo 60 Pro",
      "Realme GT 3",
      "Realme C55",
      "Outro modelo",
    ],
    Nokia: ["G60", "X30", "C32", "G21", "Outro modelo"],
    Outros: ["Outro modelo"],
  };

  const [selectedBrand, setSelectedBrand] = useState("");
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const [customModel, setCustomModel] = useState("");

  const handleBrandChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const brand = e.target.value;
    setSelectedBrand(brand);
    setAvailableModels(phoneModels[brand as keyof typeof phoneModels] || []);
    setFormData((prev) => ({ ...prev, brand, model: "" }));
  };

  const handleCustomModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomModel(value);
    setFormData((prev) => ({ ...prev, model: value }));
  };

  const handlePhoneMask = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    setFormData((prev) => ({ ...prev, phone: value }));
  };

  return (
    <ContactCss id="form">
      <div className="container">
        <Title>
          <h2>Solicite um Orçamento</h2>
          <p>Preencha o formulário abaixo e entraremos em contato</p>
        </Title>

        <FormContainer onSubmit={handleSubmit}>
          <FormSection>
            <div className="input-group">
              <input
                type="text"
                name="name"
                placeholder="Nome completo"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Telefone"
                value={formData.phone}
                onChange={handlePhoneMask}
                maxLength={15}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <select
                name="brand"
                value={formData.brand}
                onChange={handleBrandChange}
                required
              >
                <option value="">Selecione a marca</option>
                {Object.keys(phoneModels).map((brand) => (
                  <option key={brand} value={brand}>
                    {brand}
                  </option>
                ))}
              </select>
              {selectedBrand === "Outros" ||
              formData.model === "Outro modelo" ? (
                <input
                  type="text"
                  name="model"
                  placeholder="Digite o modelo do seu aparelho"
                  value={customModel}
                  onChange={handleCustomModelChange}
                  required
                />
              ) : (
                <select
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  required
                  disabled={!selectedBrand}
                >
                  <option value="">Selecione o modelo</option>
                  {availableModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              )}
              <select
                name="issue"
                value={formData.issue}
                onChange={handleChange}
                required
              >
                <option value="">Selecione o problema</option>
                <option value="Tela quebrada">Tela quebrada/trincada</option>
                <option value="Bateria">Problemas na bateria</option>
                <option value="Não liga">Não liga/reinicia</option>
                <option value="Água">Danos por líquidos</option>
                <option value="Camera">Problemas na câmera</option>
                <option value="Som">Problemas no áudio</option>
                <option value="Carregamento">Não carrega</option>
                <option value="Outros">Outros problemas</option>
              </select>
            </div>
          </FormSection>

          <ProblemDescription>
            <textarea
              name="description"
              placeholder="Descreva o problema do seu aparelho..."
              value={formData.description}
              onChange={handleChange}
              required
            />
          </ProblemDescription>

          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              "Enviando..."
            ) : (
              <>
                <FaWhatsapp /> Enviar Orçamento
              </>
            )}
            <p>Responderemos em até 24 horas úteis</p>
          </SubmitButton>
        </FormContainer>
      </div>
    </ContactCss>
  );
};

export default ContactForm;
