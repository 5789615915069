import { createGlobalStyle } from "styled-components";

// Paleta de cores para uso consistente
export const Cores = {
  primary: "#F1F4F8",
  secondary: "#FFFFFF",
  textDark: "#14181B",
  white: "#FFFFFF",
};

// Estilos globais
const GlobalCss = createGlobalStyle`
  /* Reset básico */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    list-style: none;
    text-decoration: none;
  }

  html, body {
    height: 100%;
    background-color: ${Cores.primary};
    color: ${Cores.textDark};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  /* Classe container responsiva */
  .container {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1024px) {
      padding: 16px;
    }
  }

  /* Adicione estilos adicionais ou globais conforme necessário */
`;

export default GlobalCss;
