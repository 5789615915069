import { Suspense } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Services from "../components/Services";
import ContactForm from "../components/ContactForm";
import Reviews from "../components/Reviews";
import LoadingFallback from "../components/LoadingFallback";

const MainPage = () => (
  <>
    <NavBar />
    <Suspense fallback={<LoadingFallback />}>
      <Hero />
      <Services />
      <ContactForm />
      <Reviews />
    </Suspense>
    <Footer />
  </>
);

export default MainPage;
