import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

export const FooterCss = styled.footer`
  width: 100%;
  background-color: ${Cores.secondary};
  color: ${Cores.textDark};
  padding: 60px 0 0;
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;

    iframe {
      width: 100%;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    color: ${Cores.textDark};
  }
`;

export const BusinessHours = styled.div`
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .closed {
        color: #ff0000;
        font-weight: bold;
      }
    }
  }
`;

export const LocationMap = styled.div`
  p {
    margin-top: 16px;
    font-size: 1rem;
    color: ${Cores.textDark};
  }

  iframe {
    border-radius: 8px;
    width: 100%;
    height: 250px;
  }
`;

export const SocialMedia = styled.div`
  .social-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 16px;

    a {
      color: ${Cores.textDark};
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 1rem;
      transition: opacity 0.3s, transform 0.2s;

      &:hover {
        opacity: 0.8;
        transform: scale(1.05);
      }
    }
  }

  .contact-info {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${Cores.textDark};
      font-size: 1rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Copyright = styled.div`
  text-align: center;
  padding: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  p {
    font-size: 1rem;
    color: ${Cores.textDark};
  }
`;

export const Creator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
  font-size: 0.8rem;
  color: ${Cores.textDark};

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: inherit;
    font-weight: 500;
    font-style: italic;
    text-decoration: none;
    transition: opacity 0.3s;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
`;
