import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

interface NavBarProps {
  isScrolled: boolean;
  isOpen?: boolean;
}

export const NavBarCss = styled.nav<NavBarProps>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  z-index: 1000;
  background-color: ${({ isScrolled }) =>
    isScrolled ? Cores.secondary : "transparent"};
  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const LogoImage = styled.div`
  img {
    height: 50px;
    width: auto;
    border-radius: 50%;
  }
`;

export const Menu = styled.nav<NavBarProps>`
  display: flex;

  @media (max-width: 768px) {
    position: fixed;
    top: ${({ isOpen }) => (isOpen ? "0" : "100%")};
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${Cores.secondary};
    padding: 80px 20px 40px;
    transition: top 0.3s ease-in-out;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    z-index: 1000;
  }
`;

export const Link = styled.ul`
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
`;

export const LinkItem = styled.li<NavBarProps>`
  a {
    color: ${({ isScrolled }) => (isScrolled ? "#333" : "#fff")};
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: ${({ isScrolled }) => (isScrolled ? "#333" : "#fff")};
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    a {
      color: ${Cores.textDark};

      &::after {
        background-color: ${Cores.primary};
      }
    }
  }
`;

export const MobileMenuButton = styled.button<NavBarProps>`
  display: none;
  background: none;
  border: none;
  color: ${({ isScrolled }) => (isScrolled ? Cores.textDark : Cores.secondary)};
  font-size: 1.5rem;
  cursor: pointer;
  padding: 8px;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
    position: ${({ isOpen }) => (isOpen ? "fixed" : "relative")};
    right: ${({ isOpen }) => (isOpen ? "20px" : "0")};
    transition: color 0.3s ease;
  }
`;

export const MobileContactInfo = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    margin-top: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${Cores.textDark};
  font-size: 0.9rem;

  svg {
    font-size: 1.2rem;
    color: #007bff;
  }

  span {
    font-weight: 500;
  }
`;
