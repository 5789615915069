import { useState, useEffect, useCallback } from "react";
import {
  FaBars,
  FaTimes,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import Logo from "../../assets/image/Logo.webp";
import {
  NavBarCss,
  Menu,
  Link,
  LinkItem,
  MobileMenuButton,
  LogoImage,
  MobileContactInfo,
  ContactItem,
} from "./styles";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Detectar scroll para alterar estilo da barra de navegação
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Função para alternar o estado do menu mobile
  const toggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev);

  // Função memoizada para rolar suavemente até uma seção
  const scrollToSection = useCallback((sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80; // Ajuste para compensar a altura do navbar
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });

      setIsMobileMenuOpen(false);
    }
  }, []);

  const links = [
    { id: "home", label: "Início" },
    { id: "services", label: "Serviços" },
    { id: "form", label: "Solicitar Orçamento" },
    { id: "contact", label: "Contato" },
  ];

  return (
    <NavBarCss isScrolled={isScrolled}>
      <div className="container">
        {/* Logo da aplicação */}
        <LogoImage>
          <img src={Logo} alt="Engenhoso Cell Logo" />
        </LogoImage>

        {/* Botão para abrir/fechar menu mobile */}
        <MobileMenuButton
          onClick={toggleMobileMenu}
          aria-label={isMobileMenuOpen ? "Fechar Menu" : "Abrir Menu"}
          isScrolled={isScrolled}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuButton>

        {/* Menu de navegação */}
        <Menu isOpen={isMobileMenuOpen} isScrolled={isScrolled}>
          <Link>
            {links.map(({ id, label }) => (
              <LinkItem key={id} isScrolled={isScrolled}>
                <a onClick={() => scrollToSection(id)}>{label}</a>
              </LinkItem>
            ))}
          </Link>

          {/* Informações de contato no menu mobile */}
          <MobileContactInfo>
            <ContactItem>
              <FaPhone /> <span>16 98145-1428</span>
            </ContactItem>
            <ContactItem>
              <FaEnvelope /> <span>engenhosocell@gmail.com</span>
            </ContactItem>
            <ContactItem>
              <FaMapMarkerAlt />
              <span>Rua: Ulisses de Paula Garcia, 19 - Cristais Paulista</span>
            </ContactItem>
          </MobileContactInfo>
        </Menu>
      </div>
    </NavBarCss>
  );
};

export default NavBar;
