import { ServiceCard, ServiceCss, ServicesGrid, Title } from "./styles";
import {
  FaMobile,
  FaTabletAlt,
  FaTools,
  FaMicrochip,
  FaWater,
  FaBatteryFull,
} from "react-icons/fa";

const services = [
  {
    icon: <FaMobile />,
    title: "Troca de Tela",
    description:
      "Substituímos telas quebradas ou com defeito para diversos modelos de smartphones.",
  },
  {
    icon: <FaBatteryFull />,
    title: "Troca de Bateria",
    description: "Substituímos baterias viciadas ou com baixo desempenho.",
  },
  {
    icon: <FaTools />,
    title: "Reparo de Placa",
    description: "Consertamos problemas técnicos na placa-mãe do dispositivo.",
  },
  {
    icon: <FaMicrochip />,
    title: "Problemas de Software",
    description:
      "Resolvemos problemas no sistema operacional, atualizações e remoção de vírus.",
  },
  {
    icon: <FaWater />,
    title: "Danos por Líquidos",
    description:
      "Recuperamos aparelhos que tiveram contato com água ou outros líquidos.",
  },
  {
    icon: <FaTabletAlt />,
    title: "Troca de Conectores",
    description:
      "Substituímos conectores de carga, fones de ouvido e outros componentes.",
  },
];

const Services = () => (
  <ServiceCss id="services">
    <div className="container">
      <Title>
        <h2>Nossos Serviços</h2>
        <p>Soluções profissionais para o seu dispositivo.</p>
      </Title>
      <ServicesGrid>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <div className="icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </ServiceCard>
        ))}
      </ServicesGrid>
    </div>
  </ServiceCss>
);

export default Services;
