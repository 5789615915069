import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

export const ServiceCss = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${Cores.primary};
  color: ${Cores.textDark};
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1.25rem;
    color: #666;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const ServiceCard = styled.div`
  background-color: ${Cores.secondary};
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 1.5rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }

  @media (max-width: 768px) {
    padding: 24px;

    .icon {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;
